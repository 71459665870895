import React from 'react'
import { Region, transformRegionToMap } from 'msp-integrations'
import HomeStage from '@/components/organisms/HomeStage/HomeStage'
import { StageProps } from './Stage.types'

const Stage = ({ data, regions, custom }: StageProps) => {
  const content = transformRegionToMap(regions).get('cloudinaryVideoRegion')

  return (
    <HomeStage
      mainUrl={custom.content.url}
      mainUrlText={data.mainLinkText}
      productOne={{
        id: data.productOne,
        imgSrc: custom.content.productOneImage,
        backImageSrc: custom.content.productOneBackImage,
        imageAlt: data.image1Alt
      }}
      productTwo={{
        id: data.productTwo,
        imgSrc: custom.content.productTwoImage,
        backImageSrc: custom.content.productTwoBackImage,
        imageAlt: data.image2Alt
      }}
      productThree={{
        id: data.productThree,
        imgSrc: custom.content.productThreeImage,
        backImageSrc: custom.content.productThreeBackImage,
        imageAlt: data.image3Alt
      }}
      productFour={{
        id: data.productFour,
        imgSrc: custom.content.productFourImage,
        backImageSrc: custom.content.productFourBackImage,
        imageAlt: data.image4Alt
      }}
    >
      {content && (
        <Region className={'h-full'} region={content} hideProgressBar={true} />
      )}
    </HomeStage>
  )
}

export default Stage
