import React, { useEffect, useMemo, useRef } from 'react'
import { gsap } from 'gsap'
import { CustomLinkComponent } from 'msp-components'
import { ProductFlipCard } from '@/components/molecules/ProductFlipCard'
import { FlipCardsContainerProps } from './FlipCardsContainer.types'

const FlipCardsContainer = ({
  className,
  showTiles = false,
  hideTiles = false,
  productOne,
  productTwo,
  productThree,
  productFour,
  mainLink,
  mainLinkUrl
}: FlipCardsContainerProps) => {
  const mainContainer = useRef()
  const tile1 = useRef()
  const tile2 = useRef()
  const tile3 = useRef()
  const tile4 = useRef()
  const mainLinkRef = useRef()

  useEffect(() => {
    if (hideTiles) {
      gsap
        .timeline()
        .to(mainLinkRef.current, {
          translateX: '100%',
          autoAlpha: 0,
          duration: 0.2,
          ease: 'expo.in'
        })
        .to([tile1.current, tile2.current, tile3.current, tile4.current], {
          translateY: '100%',
          stagger: 0.05,
          autoAlpha: 0,
          duration: 0.4,
          ease: 'expo.in',
          delay: 0
        })
        .to(mainContainer.current, {
          autoAlpha: 0,
          duration: 0
        })
    }
  }, [hideTiles])

  useEffect(() => {
    if (showTiles) {
      gsap
        .timeline()
        .to(mainContainer.current, {
          autoAlpha: 1,
          duration: 0
        })
        .to([tile1.current, tile2.current, tile3.current, tile4.current], {
          translateY: '0%',
          stagger: 0.3,
          autoAlpha: 1,
          duration: 1,
          ease: 'expo.out',
          delay: 0.2
        })
        .to(mainLinkRef.current, {
          translateX: '0%',
          autoAlpha: 1,
          duration: 0.2,
          delay: 0,
          ease: 'expo.out'
        })
    }
  }, [showTiles])

  return (
    <div
      className={`invisible absolute right-0 top-0 mt-[3vw] overflow-hidden opacity-0 ${className}`}
      ref={mainContainer}
    >
      <div className={'flex h-full w-full flex-row'}>
        <div className={'flex-col'}>
          <div className={'invisible p-[1vw] opacity-0'} ref={tile1}>
            <ProductFlipCard
              productId={productOne.id}
              productCoverImageUrl={productOne.imgSrc}
              productDetailImageUrl={productOne.backImageSrc}
              imageAlt={productOne.imageAlt}
            />
          </div>
          <div className={'invisible p-[1vw] opacity-0'} ref={tile2}>
            <ProductFlipCard
              productId={productTwo.id}
              productCoverImageUrl={productTwo.imgSrc}
              productDetailImageUrl={productTwo.backImageSrc}
              imageAlt={productTwo.imageAlt}
            />
          </div>
        </div>
        <div className='mt-[2vw] flex-col'>
          <div
            className={'invisible flex justify-end p-[1vw] opacity-0'}
            ref={mainLinkRef}
          >
            <CustomLinkComponent href={mainLinkUrl}>
              {mainLink}
            </CustomLinkComponent>
          </div>
          <div className={'invisible p-[1vw] opacity-0'} ref={tile3}>
            <ProductFlipCard
              productId={productThree.id}
              productCoverImageUrl={productThree.imgSrc}
              productDetailImageUrl={productThree.backImageSrc}
              imageAlt={productThree.imageAlt}
            />
          </div>
          <div className={'invisible p-[1vw] opacity-0'} ref={tile4}>
            <ProductFlipCard
              productId={productFour.id}
              productCoverImageUrl={productFour.imgSrc}
              productDetailImageUrl={productFour.backImageSrc}
              imageAlt={productFour.imageAlt}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlipCardsContainer
